.passwordInput {
	position: relative;
}

.passwordInput:not(:has(.inputReadOnly, .inputError)):focus-within .title {
	color: var(--primary-100);
}

.title,
.loadingIcon {
	position: absolute;
	z-index: 1;
}

.title,
.loadingIcon {
	user-select: none;
	pointer-events: none;
}

.title {
	font-size: 13px;
	font-weight: 500;
	line-height: 14px;
	top: 10px;
	left: 16px;
	color: #7d7d7d;
	transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.titleError {
	color: var(--error-100);
}

.questionMarkIcon {
	margin-left: 12px;
	cursor: help;
	pointer-events: all;
}

.loadingIcon {
	top: 50%;
	right: 16px;
	transform: translateY(-50%);
}

.visibilityToggleIcon {
	position: absolute;
	top: 50%;
	right: 16px;
	translate: 0 -50%;
	cursor: pointer;
}

.input {
	font-size: 14px;
	line-height: 18px;
	width: 100%;
	height: 60px;
	padding: 30px 16px 10px 16px;
	color: var(--black-100);
	background: var(--bg-20);
	border: 1px solid transparent;
	outline: none;
	transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		border 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.input:not(.inputReadOnly, .inputError):focus-within {
	background: #fff !important;
	border: 1px solid var(--primary-100) !important;
}

.input:disabled {
	cursor: not-allowed;
}

.inputSmall {
	font-size: 12px;
}

.inputLarge {
	font-size: 16px;
}

.inputWithoutTitle {
	padding: 10px 16px;
}

.inputReadOnly {
	border: 1px solid #b5b5b5;
	background: var(--bg-50);
}

.inputError {
	background: var(--error-10);
	border: 1px solid var(--error-100);
}
