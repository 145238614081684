.root,
.backgroundContainer,
.rememberBtnContainer,
.orTextContainer {
	display: grid;
}

.root {
	text-align: center;
	place-items: center;
	height: 100vh;
	background: #f7f7f7;
}

.root p {
	margin: 0;
}

.loginForm {
	position: relative;
}

.loginForm {
	border-radius: 2px;
}

.loginForm {
	width: 462px;
	padding: 60px 56px;
	background: #fff;
	box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.04);
}

.logo {
	margin-bottom: 60px;
}

.errorMessage,
.forgotPassword,
.signupLink {
	font-weight: 500;
}

.errorMessage {
	text-align: left;
	margin-bottom: 24px;
	color: #ff5959;
}

.errorMessage,
.rememberText .forgotPassword {
	font-size: 14px;
	line-height: 14px;
}

.inputsContainer {
	display: grid;
	gap: 12px;
}

.rememberBtnContainer {
	grid-template-columns: repeat(2, max-content) 1fr;
	gap: 8px;
	align-items: center;
	margin: 24px 0;
	line-height: 14px;
}

.rememberText {
	color: #052a33;
}

.forgotPassword {
	color: #288ea5;
	justify-self: end;
}

.loginBtn {
	width: 100%;
	margin-bottom: 16px;
    height: 50px;
    font-size: 15px;
}

.signupLinkContainer {
	font-size: 15px;
	line-height: 18px;
	margin: 24px 0px;
	color: #656565;
}

.orTextContainer {
	font-size: 12px;
	line-height: 15px;
	color: #656565;
	grid-template-columns: repeat(3, min-content);
	align-items: center;
	justify-content: center;
	gap: 16px;
	margin-bottom: 12px;
}

.line {
	width: 100px;
	height: 1px;
}

.lineLeft {
	background: linear-gradient(270deg, #e5e5e5 0%, rgba(238, 238, 238, 0) 100%);
}

.lineRight {
	background: linear-gradient(270deg, rgba(238, 238, 238, 0) 0%, #e5e5e5 100%);
}

.googleSignupBtnContainer {
	width: 100%;
}

.googleSignupBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    gap: 12px;
    height: 50px;
    font-size: 15px;
}

.googleSignupBtnContainer .googleSignupBtn,
.googleSignupBtnContainer .googleSignupBtn:hover,
.googleSignupBtnContainer .googleSignupBtn:active,
.googleSignupBtnContainer .googleSignupBtn:focus {
	gap: 16px;
	color: #052a33;
	border: 1px solid #ebebeb;
}

.googleSignupBtnContainer .googleSignupBtn:hover {
	color: var(--primary-100);
	border: 1px solid var(--primary-100);
}
